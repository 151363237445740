const featureToggles = {
  soldOut: () => false,
  register: () => false,
  schedule: () => false,
  secondSchedule: () => false,
  archive: () => true,
  partners: () => true,
  contactFormAuth: () => true,
  liveStream: () => false,
  pressReleases: () => true,
};
export default featureToggles;