import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import schedules from '../../../assets/static-data/2024/secondSchedule.json';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import { Schedule } from '@kainos-applied-innovation/convention-frontend-components/';

const SecondSchedulePage = () => {

  const [secondScheduleList, setSecondSchedule] = useState(schedules.schedules);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Schedule.Title');

    if (!secondScheduleList) {
      setSecondSchedule(schedules);
    }
  }, []);

  if (!featureToggles.secondSchedule()) {
    return <ComingSoonPage title={t('Pages.Schedule.Title')} header={t('Pages.SecondSchedule.Header')} />;
  }
  
  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.SecondSchedule.Header')}</h1>
        </div>
        <h3 className = "con-heading__m">{t('Pages.SecondSchedule.Location')}</h3>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.SecondSchedule.AICC')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.SecondSchedule.Invite')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.SecondSchedule.Workshops')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.SecondSchedule.Outro')}
        </p>
        <h3 className = "con-heading__m">{t('Pages.SecondSchedule.Subheader.Schedule')}</h3>
        {<Schedule schedule={secondScheduleList} t={t} /> }
      </div>
    </div>
  );
};

export default SecondSchedulePage;
